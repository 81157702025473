import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import MathExpress from "../../Assets/Projects/calcK.png";
import emotion from "../../Assets/Projects/emotion.png";
import chatify from "../../Assets/Projects/mazePic.png";
import suicide from "../../Assets/Projects/suicidee.webp";
import bitsOfCode from "../../Assets/Projects/aiutoPic.jpeg";
import MemoAR from "../../Assets/Projects/memoAR.png";
import Akinator from "../../Assets/Projects/akinatorrr.png";
import FPS from "../../Assets/Projects/fpsG.webp";
import QRCode from "../../Assets/Projects/QRR.jpeg";
import seizure from "../../Assets/Projects/seizuree.png";
import slack from "../../Assets/Projects/slack.png";
import Husi from "../../Assets/Projects/Husi.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Featured <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="AI Puzzle Solver"
              description="Using Jupyter, Kaggle, OpenCV, and BFS I developed a program that can create any random Puzzle, solve it or solve any uploaded images of them.  Also applicable for chess boards or sudokus."
              ghLink="https://github.com/suneruperera0/Puzzle-Maze-Solver"
              demoLink="https://drive.google.com/file/d/1XAqbUddbi73fOAMaDjm1fTLP1FHVn1Ou/view?usp=sharing"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Aiuto Solutions"
              description="A crowdfunding website I built for the company Aiuto Solutions, to help fund money for retirement homes and help the senior citiznes on Waterloo/Kitchener.  Built around HTML/CSS, Javascript/Typescript, Vue, MongoDB."
              ghLink="https://github.com/suneruperera0/AiutoSol"
              demoLink="https://youtu.be/qShjJG-IOZA"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title = "SuicidEye"
              description="An AI application that searches the web and social media for posts relating to suicide/self harm. Using natural language processing, and trained with the model using Bidirectional LSTM with GLOBE."
              ghLink="https://github.com/suneruperera0/SuicidEye"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Husi}
              isBlog={false}
              title="Husi"
              description="By streamlining the process of turning everyday tasks into opportunities, Husi connects local individuals seeking services with providers. "
              ghLink="https://github.com/husitech/husi"
              demoLink="https://www.figma.com/file/Ma7uLEzvF7TCU9AH7fff9Z/Husi?type=design&node-id=0%3A1&mode=dev"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MathExpress}
              isBlog={false}
              title="MathLify"
              description="Create a handwritten math expression simplifier program using image recognition (convolutional neural network)"
              ghLink="https://replit.com/@SuneruPerera/Math-Expression-Simplifier"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MemoAR}
              isBlog={false}
              title="MemoAR"
              description="MemoAR provides users with a seamless narrative of any statute with our AR prototype! Users are able to scan a QR code located on the statue which brings them into an augmented reality narration, providing them with a backstory. Built by using Zappar Universal AR SDK for PlayCanvas, Blender, SketchFab, Mixamo, and Canva."
              demoLink="https://www.figma.com/proto/tTPksKfNUSXXuCALvDwgTT/MemoAR?node-id=507-157&scaling=scale-down&page-id=0%3A1&starting-point-node-id=2%3A21"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink=""
              // https://github.com/soumyajit4419/Face_And_Emotion_Detection     
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Akinator}
              isBlog={false}
              title="AI Akinator Discord Bot"
              description="Created a discord bot that mimics 'Akinator-Bot' and is able to guess any character than the user thinks of.  Developed with AI and Python.  
              Previously used by 20,000+ users on discord.
              "
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FPS}
              isBlog={false}
              title="FPS Game"
              description="Create a handwritten math expression simplifier program using image recognition (convolutional neural network)
              "
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={QRCode}
              isBlog={false}
              title="QR Scanner"
              description="MRS Company Project
              "
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={seizure}
              isBlog={false}
              title="Seizure Detector"
              description="Hardware Detector
              "
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={slack}
              isBlog={false}
              title="SLACGKW (SriLankan-Canadian) Association Website"
              description="Built a website for the SriLankan-Canadian association, to keep everyone in touch and contact the committees.
              "
              ghLink=""
              demoLink="https://slacgkw.vercel.app"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
